const initialState = {
  user: {},
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case "USER_SAVE": {
      return { ...state, user: action.payload };
    }

    default: {
      return state;
    }
  }
};

export default user;
