import { createBrowserHistory } from "history";
import { handleRTL } from "../layout";

const history = createBrowserHistory();

export const handleLogout = () => {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT",
    });

    dispatch({
      type: "LOGOUT",
    });

    dispatch(handleRTL(false));
    alert("Yes");
    window.localStorage.setItem("language", "en");
    window.location.reload();
  };
};

export const saveUser = (payload) => {
  return { type: "USER_SAVE", payload };
};
